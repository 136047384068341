<template>
  <v-card class="mx-auto mt-5 mb-5" elevation="4" max-width="800">
    <v-card-title class="justify-center">New Video</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="title"
              :rules="req"
              label="Title"
              outlined
              :loading="loading"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="order"
              :rules="req"
              label="Order"
              outlined
              type="number"
              :loading="loading"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="videoUrl"
              label="Video URL"
              outlined
              :loading="loading"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="description"
              :rules="req"
              label="Description"
              outlined
              :loading="loading"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <div v-html="description"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="shortDescription"
              :rules="req"
              label="Short Description"
              outlined
              :loading="loading"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <div v-html="shortDescription"></div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox v-model="otoCheckbox" label="OTO Required"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="showCheckbox" label="Show"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="submit"
        :disabled="loading"
        :loading="loading"
        color="primary"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "VideoNew",
  components: {},
  mixins: [hasuraService],
  methods: {
    async submit() {
      this.loading = true;
      this.valid = await this.$refs.form.validate();
      if (!this.valid) {
        this.loading = false;
        // show snackbar
        this.$store.commit(
          "set_snackbar",
          "Please fill out all required fields."
        );

        return;
      }
      if (this.shortDescription.length < 10) {
        this.loading = false;
        this.$store.commit(
          "set_snackbar",
          "Short description must be at least 10 characters."
        );

        return;
      }
      if (this.description.length < 10) {
        this.loading = false;
        this.$store.commit(
          "set_snackbar",
          "Description must be at least 10 characters."
        );
        return;
      }
      // We know the form is valid
      let id = await this.createVideo({
        cf_embed: this.videoUrl,
        description: this.description,
        oto_required: this.otoCheckbox,
        sequence: this.order,
        short_description: this.shortDescription,
        show: this.showCheckbox,
        title: this.title,
      });
      this.$store.commit("set_snackbar", "Video created successfully: " + id);

      this.loading = false;
    },
  },
  data() {
    return {
      req: [(v) => !!v || "Field is required"],
      title: "",
      videoUrl: "",
      description: "",
      shortDescription: "",
      order: "",
      checkbox: true,
      loading: false,
      otoCheckbox: true,
      showCheckbox: true,
      valid: true,
      config: {
        modules: {
          toolbar: [
            [
              { font: [] },
              // h1, h2...
              { header: [1, 2, 3, 4, 5, 6, false] },
            ],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            ["clean"],
          ],
        },
      },
    };
  },
};
</script>
